<template>
  <v-dialog
      style="z-index: 1000"
      v-model="internalDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >

    <v-card>
      <v-toolbar dark color="primary" flat>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>选择地址</v-toolbar-title>
        <v-spacer></v-spacer>
        <br>
      </v-toolbar>

      <v-toolbar dark color="primary" style="transform: translateY(-5px)">

        <v-btn text>
          {{ this.province }}
          <v-icon right>
            keyboard_arrow_down
          </v-icon>
        </v-btn>
        <v-text-field
            v-model="inputAddress"
            label="搜索小区名/大厦名"
            outlined
            clearable hide-details
            dense
            @input="changeInputAddress"
        ></v-text-field>
      </v-toolbar>

      <v-list
          three-line
          subheader
          v-if="locations.length"
      >
        <v-list-item class="location-list" v-for="location in locations" :key="location.name">
          <v-list-item-action>
            <v-icon>pin_drop</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="selectLocation(location)">
            <v-list-item-title>{{ location.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ location.address }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-if="locations.length === 0">
        暂无数据
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {getAddrByLocation, getSearchMapByCoordinate, getSearchMapByKeywords} from "@/api/worker";

export default {
  name: "AddressList",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      lat: 0,
      lng: 0,
      province: '',
      locations: [],
      inputAddress: '',
    }
  },
  computed: {
    internalDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    }
  },

  watch: {
    internalDialog(newVal) {
      if (newVal) {
        this.getCurrent();
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    confirmNavigation() {
      this.$emit('update:modelValue', false)
      this.$emit('closeDialog');
    },
    getCurrent() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              console.log("lat: ", this.lat, "lng: ", this.lng)
              this.getAddrByLocation()
              this.getSearchMapByCoordinate()
            },
            error => {
              console.error("获取位置失败:", error);
              alert("获取位置失败 请手动选择")
            }
        );
      } else {
        console.error("浏览器不支持地理位置功能。");
        alert("获取位置失败 请手动选择")
      }
    },

    getAddrByLocation() {
      getAddrByLocation(this.lat, this.lng).then(res => {
        this.province = res.data.result.province;
      })
    },

    getSearchMapByCoordinate() {
      getSearchMapByCoordinate(this.lng, this.lat, 1, 20).then(res => {
        this.locations = res.data.result
        console.log("getSearchMapByCoordinate: ", this.locations)
      })
    },

    changeInputAddress() {
      if (this.inputAddress === "") return
      getSearchMapByKeywords(this.province, this.inputAddress, 0, 20, this.lng, this.lat).then(res => {
        console.log("getSearchMapByKeywords: ", res)
        this.locations = res.data.result
        if (!Array.isArray(this.locations)) this.locations = [];
      })
    },
    selectLocation(location) {
      let components = [location.pname, location.cityName, location.adname, location.address];
      let addr = components.filter(component => component).join('');
      let locationObj = {
        address: location.address,
        formatted_address: addr,
        lat: parseFloat(location.location.split(",")[1]),
        lng: parseFloat(location.location.split(",")[0]),
        name: location.name,
      };
      console.log("selectLocation: ", locationObj);
      this.$emit('selectLocation', locationObj);
      this.closeDialog();
    }
  }
}
</script>

<style scoped>
.location-list {
  border-bottom: 1px solid rgba(186, 186, 186, 0.5);
}
</style>
