<!-- src/components/Details.vue -->
<template>
  <div>
    <h1>获取预定链接</h1>
    <input v-model="url" placeholder="输入酒店公寓Google Maps URL">
    <button @click="generateBookingLink">生成链接</button>
    <div v-if="bookingUrl">
      <p>生成的链接: <a :href="bookingUrl" target="_blank">{{ bookingUrl }}</a></p>
      <button @click="copyToClipboard">复制链接</button>
    </div>
    <p v-if="hotelName">酒店名称: {{ hotelName }}</p>
    <p v-if="latitude && longitude">纬度: {{ latitude }}, 经度: {{ longitude }}</p>
    <p v-else-if="parsed">未找到经纬度信息</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      hotelName: null,
      latitude: null,
      longitude: null,
      parsed: false,
      bookingUrl: ''
    };
  },
  methods: {
    parseUrl() {
      this.hotelName = this.extractHotelName(this.url);
      this.latitude = this.extractLatitude(this.url);
      this.longitude = this.extractLongitude(this.url);
      this.parsed = true;
    },
    extractHotelName(url) {
      // eslint-disable-next-line
      const match = url.match(/place\/([^\/]+)/);
      return match ? decodeURIComponent(match[1]) : null;
    },
    extractLatitude(url) {
      const match = url.match(/@([-.\d]+),([-.\d]+)/);
      return match ? match[1] : null;
    },
    extractLongitude(url) {
      const match = url.match(/@([-.\d]+),([-.\d]+)/);
      return match ? match[2] : null;
    },
    generateBookingLink() {
      this.parseUrl();
      if (this.latitude && this.longitude && this.hotelName) {
        this.bookingUrl = `https://booking.enjoyinstantly.com?latitude=${this.latitude}&longitude=${this.longitude}&hotel=${encodeURIComponent(this.hotelName)}`;
      } else {
        alert('无效的URL，请输入有效的Google Maps URL');
      }
    },
    copyToClipboard() {
      if (this.bookingUrl) {
        navigator.clipboard.writeText(this.bookingUrl).then(() => {
          alert('链接已复制到剪贴板');
        }).catch(err => {
          console.error('复制失败:', err);
        });
      }
    }
  }
};
</script>

<style scoped>
/* 你的样式 */
</style>
