<template>
  <v-dialog
      style="z-index: 2000"
      v-model="dialog"
      persistent
      max-width="400px"
  >
<!--    <template v-slot:activator="{ on, attrs }">-->
<!--      <v-btn-->
<!--          color="primary"-->
<!--          dark-->
<!--          v-bind="attrs"-->
<!--          v-on="on"-->
<!--      >-->
<!--        联系客服-->
<!--      </v-btn>-->
<!--    </template>-->
    <v-card>
      <v-card-title>
        <span class="text-h5">联系客服</span>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
              v-for="(item) in sortedList"
              :key="item.id"
              @click="handleClick(item)"
          >
            <v-list-item-avatar>
              <img :src="item.iconUrl" :alt="item.title"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.account">
                {{ item.account }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          好的
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getContactLink} from "@/api/worker";

export default {
  data() {
    return {
      dialog: false,
      list: []
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getContactLink();
      }
    }
  },
  computed: {
    sortedList() {
      // 根据 orderIndex 对 list 进行排序
      return this.list.slice().sort((a, b) => a.orderIndex - b.orderIndex);
    }
  },
  methods: {
    getContactLink() {
      getContactLink().then(res => {
        this.list = res.data.result;
        console.log('getContactLink: ', this.list);
      });
    },
    handleClick(item) {
      if (item.isOpenBrowser && item.linkUrl) {
        this.openInNewTab(item.linkUrl);
      } else if (item.account) {
        this.copyToClipboard(item.account);
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        alert("账号已复制到剪贴板！");
      }).catch(err => {
        console.error('复制失败:', err);
      });
    },
    openInNewTab(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>

</style>
