<template>
  <div>
    <LoadingIndicator v-model="loading"/>
    <TopBack/>
    <div v-if="showGallery && GalleryOrVideo === 'gallery'">
      <ImageSlider :banners="this.images" ></ImageSlider>
    </div>
    <div v-if="showVideo!=null && GalleryOrVideo === 'video'">
      <div style="height: 450px">
        <vueMiniPlayer class="video-container" :video="video" :mutex="true"/>
      </div>

    </div>
    <div v-if="showGallery && videoList">
      <v-tabs>
        <v-tab @click="GalleryOrVideo = 'gallery'">相册</v-tab>
        <v-tab @click="GalleryOrVideo = 'video'">视频</v-tab>
      </v-tabs>
    </div>

    <div v-if="workerDto" class="order-content">
      <div>
        <div class="worker-info">
          <div class="text-h5">
            {{ workerDto.name }}
            <v-icon class="icon-offset" v-if="workerDto.gender === 2" color="#F06292">mdi-gender-female</v-icon>
            <v-icon class="icon-offset" v-else color="#3571e4">mdi-gender-male</v-icon>
          </div>
          <p class="text-subtitle-1" style="margin: 0;align-items: center;display: flex">
            <v-icon class="icon-offset">location_on</v-icon>
            {{ (workerDto.distance / 1000) }} km
          </p>
        </div>
        <div class="worker-info">
          <div>
            <v-chip small filter>{{ workerDto.province }}</v-chip>&nbsp;
            <v-chip small filter>年龄{{ workerDto.age }}</v-chip>
          </div>
          <v-chip color="red" text-color="white" class="text-subtitle-1">
            &nbsp;+ 关注&nbsp;
          </v-chip>
        </div>
        <div class="worker-info text-subtitle-2">
          <div>
            <v-icon small>storefront</v-icon>
            {{ merchantDto.name }}
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="info-grid">
        <div>生肖 <strong>{{ workerDto.zodiac }}</strong></div>
        <div>星座 <strong>{{ workerDto.zodiac }}</strong></div>
        <div>民族 <strong>{{ workerDto.nation }}</strong></div>
        <div>学历 <strong>{{ workerDto.education }}</strong></div>
        <div>身高 <strong>{{ workerDto.height }}</strong></div>
        <div>体重 <strong>{{ workerDto.weight > 0 ? workerDto.weight : '未知' }}</strong></div>
        <div>语言 <strong>{{ languages }}</strong></div>
      </div>
      <p class="text-subtitle-2" style="margin: 5px 0">简介: {{ workerDto.desc }}</p>
      <v-divider></v-divider>
      <div class="worker-info text-subtitle-1">
        <div>服务的项目</div>
        <div class="red--text" @click="toWorkerComment">评论数量: ({{ workerDto.commentCount }}) 好评:
          {{ workerDto.favourableCommentRate }}% >
        </div>
      </div>

      <v-divider></v-divider>
    </div>


    <div v-if="productList.length > 0" class="order-content">
      <div v-for="product in productList" :key="product.id" class="product"
           @click="getServiceHtmlContent(product.id, product.name, product.price, images[0], workerDto.name, workerId)">
        <!--        <v-card outlined>-->
        <div class="product-list">
          <div style="flex: 0 0 110px">
            <img :src="product.imageUrl" alt="Product Image" width="110" height="110"/>
          </div>
          <div style="flex: 1;line-height: 180%">
            <div style="display: flex; justify-content: space-between;max-width: 100%">
              <div class="d-inline-block text-truncate text-subtitle-1" style="max-width: 85px;">{{
                  product.name
                }}
              </div>
              <div class="text-subtitle-2 " style="display: flex;align-items: center;">
                {{ product.serviceTime }}分钟&nbsp;
                <v-chip color="red" outlined small>
                  {{ product.recentDate }} {{ product.recentFreeTime }}
                </v-chip>
              </div>
            </div>
            <div class="text-body-1">
              已售{{ product.soldCount }} 上门服务
            </div>
            <div class="text-body-1">
              {{ product.descContent }}
            </div>
            <div style="display: flex; justify-content: space-between">
              <div class="text-h6 red--text text--darken-1">฿{{ product.price }}/次</div>
              <v-chip @click.stop="ProjectAppointment(product.id)" color="red" text-color="white">立即预约</v-chip>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <!--        </v-card>-->
      </div>
    </div>

  </div>
</template>

<script>
import {getMerchantProductList, getWorkerLanguages, getWorkerVideoList, workerDetail} from "@/api/worker";
import ImageSlider from "@/components/common/ImageSlider.vue";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import TopBack from "@/components/common/navigation/TopBack.vue";

export default {
  name: "WorkerDetail",
  components: {
    TopBack,
    LoadingIndicator,
    ImageSlider,
  },
  data() {
    return {
      workerDto: {},
      merchantDto: {},
      workerId: this.$route.params.id || null,
      latitude: null, // 初始化经纬度
      longitude: null,
      images: [],
      index: 1,
      showGallery: false,
      showVideo: false,
      productList: [],
      videoList: {},
      GalleryOrVideo: 'gallery',
      video: {
        url: '',
        cover: '',
        muted: false,
        loop: false,
        preload: 'auto',
        poster: '',
        volume: 1,
        autoplay: false
      },
      languages: '',
      loading: true
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.getRouterData()
    this.getMerchantProductList(this.workerId)
    this.getWorkerVideoList(this.workerId)
    this.getWorkerLanguages()
  },

  methods: {
    getRouterData() {
      this.workerId = this.$route.query.id
      console.log('workerId', this.workerId)
      this.latitude = this.$route.query.latitude || 0
      this.longitude = this.$route.query.longitude || 0
      console.log(this.latitude, this.longitude)
      this.fetchWorkerDetail()
    },
    fetchWorkerDetail() {
      workerDetail(this.workerId, this.latitude, this.longitude).then(response => {
        const result = response.data.result;
        console.log("workerDetail: ", result);
        this.workerDto = result.workerDto;
        this.merchantDto = result.merchantDto;
        this.images = result.photographs.map(item => ({
          src: `${item.photoUrl}`,
          title: '图片标题' // 这里可以根据需求设置标题
        }));
        this.images = result.photographs.map(photo => photo.photoUrl);
        console.log('images: ', this.images);
        this.index = 1;
        this.showGallery = true;
        this.loading = false
      }).catch(error => {
        console.error('Error fetching worker details:', error);
      });
    },
    getMerchantProductList(workerId) {
      getMerchantProductList(workerId).then((res) => {
        this.productList = res.data.result
        console.log("getMerchantProductList: ", this.productList)
      })
    },
    getWorkerLanguages() {
      getWorkerLanguages(this.workerId).then(res => {
        const languageCode = res.data.result;
        console.log('getWorkerLanguages: ', languageCode);
        const displayNames = new Intl.DisplayNames([localStorage.getItem('userLocale')], {type: 'language'});
        this.languages = languageCode.map(code => displayNames.of(code)).join(', ');
      })
    },
    getServiceHtmlContent(productId, productName, productPrice, avatar, name, workerId) {
      this.$router.push({
        name: 'htmlContent',
        query: {
          productId: productId,
          productName: productName,
          productPrice: productPrice,
          avatar: avatar,
          name: name,
          workerId: workerId
        }
      })
    },
    getWorkerVideoList(workerId) {
      getWorkerVideoList(workerId).then((res) => {
        this.videoList = res.data.result[0];
        if (this.videoList === undefined) return
        this.video.url = this.videoList.videoUrl
        console.log("videoList: ", this.videoList)
      })
    },
    ProjectAppointment(productId) {
      this.$router.push({
        name: 'ProjectAppointment',
        query: {
          productId: productId,
          workerId: this.workerId,
          workerName: this.workerDto.name,
          avatar: this.workerDto.avatarUrl
        }
      })
    },
    toWorkerComment() {
      this.$router.push({
        name: 'WorkerComment',
        query: {
          workerId: this.workerId,
        }
      })
    }
  }
}
</script>

<style scoped>

.product {
  margin-top: 5px;
}

.worker-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
}

.info-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  text-align: left;
  margin-top: 5px;
}

.product-list {
  display: flex;
  gap: 15px;
}

.text-overflow {
  width: 45%;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button {
  padding: 2px 5px 2px 5px;
}

.order-content {
  padding: 0 10px;
}

.icon-offset {
  position: relative;
  top: -3px;
}
</style>
