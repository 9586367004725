import { render, staticRenderFns } from "./WorkerDetail.vue?vue&type=template&id=6d9546ac&scoped=true"
import script from "./WorkerDetail.vue?vue&type=script&lang=js"
export * from "./WorkerDetail.vue?vue&type=script&lang=js"
import style0 from "./WorkerDetail.vue?vue&type=style&index=0&id=6d9546ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d9546ac",
  null
  
)

export default component.exports