import BookingPage from "@/components/booking/BookingPage.vue";
import OrdersPage from "@/components/orders/OrdersPage.vue";
import ProfilePage from "@/components/profile/ProfilePage.vue";
import LoginPage from "@/components/profile/LoginPage.vue";
import GenLink from "@/components/common/map/GenLink.vue";
import WorkerDetail from "@/components/booking/WorkerDetail.vue";
import ServiceHtmlContent from "@/components/booking/HtmlContent.vue";
import ProjectAppointment from "@/components/booking/ProjectAppointment.vue";
import VueRouter from "vue-router";
import {useStore} from "@/config/store";
import OrderDetail from "@/components/orders/OrderDetail.vue";
import RegisterPage from "@/components/profile/RegisterPage.vue";
import WorkerComment from "@/components/booking/WorkerComment.vue";


const routes = [
    {path: '/', component: BookingPage, name: 'BookingPage', meta: {keepAlive: true},},
    {path: '/orders', component: OrdersPage, name: 'OrdersPage', meta: {keepAlive: true, requiresAuth: true},},
    {path: '/order/:orderId', component: OrderDetail, name: 'OrderDetail'},
    {path: '/profile', component: ProfilePage, name: 'ProfilePage', meta: {keepAlive: true},},
    {path: '/login', component: LoginPage, name: 'LoginPage'},
    {path: '/register', component: RegisterPage, name: 'RegisterPage'},
    {path: '/genLink', component: GenLink},
    {path: '/detail', component: WorkerDetail, name: 'Detail'},
    {path: '/comment', component: WorkerComment, name: 'WorkerComment'},
    {path: '/htmlContent', component: ServiceHtmlContent, name: 'htmlContent'},
    {path: '/order', component: ProjectAppointment, name: 'ProjectAppointment'},
];

const router = new VueRouter({
    routes
});


router.beforeEach((to, from, next) => {
    // console.log('Routing from:', from.fullPath, 'to:', to.fullPath);
    const store = useStore(window.pinia);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.isLogined) {
            next({name: 'LoginPage'});
        } else {
            next();
        }
    } else if (to.name === 'LoginPage' && store.isLogined) {
        next({name: 'ProfilePage'});
    } else {
        next();
    }
});

export {router}