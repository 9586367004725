<template>
  <div>
    <LoadingIndicator :value="loading"/>
    <TopBar title="登录" :leftIcon="'arrow_back'"/>

    <OverflowDialog
        v-model="UserAgreementShow"
        title="Test"
        content="dialogContent"
        @confirm="UserAgreementShow = false"
        @close="UserAgreementShow = false"
    />

    <v-snackbar :color="color" text v-model="snackbar">
      <strong class="text-subtitle-1">{{ text }}</strong>
    </v-snackbar>

    <div class="order-content">
      <img class="logo" width="120" height="120" :src="require('../../assets/enjoyinstantlylogo.png')" alt="">
      <v-form ref="form" class="form-info" v-model="valid" lazy-validation>
        <VueCountryIntl
            v-show="showCountryIntl" :showAreaCode="true"
            :showLabelImg="true" schema="popover"
            v-model="intercode">
          <v-btn small color="primary" slot="reference">选择区号 当前:{{ intercode }}</v-btn>
        </VueCountryIntl>
        <v-text-field
            v-model="phoneNum" :rules="phoneRules" filled placeholder="请输入手机号码"
            label="手机号码" prepend-inner-icon="call" @focus="showCountryIntl = true"
        ></v-text-field>

        <v-text-field
            v-if="this.show === 'password'" v-model="password"
            :rules="passwordRules" type="password" filled
            placeholder="请输入密码" label="密码" prepend-inner-icon="lock"
        ></v-text-field>

        <div v-else style="display: flex;flex-direction: row;gap: 10px;">
          <v-text-field
              v-model="verificationCode"
              :rules="verificationCodeRules"
              filled
              placeholder="请输入验证码"
              label="验证码"
              prepend-inner-icon="verified_user"
          ></v-text-field>
          <!--          <v-btn style="margin: 10px 0 0 0" color="success" @click="sendVerificationCode">获取验证码</v-btn>-->
          <v-btn
              style="margin: 10px 0 0 0" color="orange"
              @click="sendVerificationCode" :disabled="codeCount > 0"
          >
            {{ codeCount > 0 ? `${codeCount}秒后重试` : '获取验证码' }}
          </v-btn>

        </div>

        <v-radio-group v-model="agree" style="margin: 0" row>
          <v-radio :value="true" style="margin: 0"></v-radio>
          <div class="text-subtitle-2">
            登录即代表同意
            <v-btn @click="loadUserAgreement" style="padding: 0" text color="error">《用户协议》</v-btn>
            和
            <v-btn style="padding: 0" text color="error">《隐私政策》</v-btn>
          </div>
        </v-radio-group>

        <v-btn
            v-if="this.show === 'password'"
            class="login" color="warning" elevation="2"
            rounded x-large @click="submitFormByPwd" :disabled="!valid"
        >
          登录
        </v-btn>
        <v-btn v-else
               class="login" color="warning" elevation="2" rounded x-large
               @click="submitFormByCode" :disabled="!valid"
        >
          验证码登录
        </v-btn>

      </v-form>
      <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 20px">
        <v-btn v-if="this.show === 'password'"
               @click="show = 'verificationCode'"
               text color="orange" text-color="white">验证码登录
        </v-btn>
        <v-btn v-else @click="show = 'password'" text color="">密码登录</v-btn>
        <v-btn text @click="$router.push({name:'RegisterPage'})" color="orange">新用户注册</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import OverflowDialog from "@/components/common/OverflowDialog.vue";
import axios from "axios";
import {codeLogin, getCode, getEIIdtoken} from "@/api/worker";
import {useStore} from "@/config/store";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";

export default {
  name: "LoginPage",
  components: {LoadingIndicator, OverflowDialog, TopBar},
  computed: {
    store() {
      return useStore()
    }
  },
  data() {
    return {
      phoneNum: '',
      password: '',
      agree: false,
      UserAgreementShow: false,
      PrivacyPolicyShow: false,
      valid: false,
      intercode: 86,
      verificationCode: '',
      codeCount: 0,
      phoneRules: [
        v => !!v || '请输入手机号码',
        v => (this.intercode === 86 ? /^1[3456789]\d{9}$/.test(v) : v.length > 7) || '请输入有效的手机号码',
      ],
      passwordRules: [
        v => !!v || '请输入密码',
        v => (v.length >= 6) || '密码长度应至少为6个字符',
      ],
      verificationCodeRules: [
        v => !!v || '请输入验证码',
        v => (v.length === 5) || '验证码长度应为5个字符',
      ],
      showCountryIntl: false,
      snackbar: false,
      text: '',
      color: 'primary',
      show: 'password',
      loading: false
    };
  },
  methods: {
    loadUserAgreement() {
      axios.get('http://iot.leehuu.cn/go/ServiceContract?proxyId=7050280').then(res => {
        console.log(res)
      })
      this.UserAgreementShow = true
    },
    submitFormByPwd() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (!this.agree) {
        alert('请您同意用户协议，继续登录');
        return;
      }
      console.log('提交表单:', {
        phoneNum: this.phoneNum,
        password: this.password,
        intercode: this.intercode,
        os: 1
      });
      this.getEIIdtoken()
    },

    getEIIdtoken() {
      this.loading = true
      getEIIdtoken(this.phoneNum, this.password, 1, this.intercode).then(res => {
        const data = res.data
        console.log("getEIIdtoken.data: ", data)
        switch (data.retCode) {
          case 0:
            // console.log('login success')
            this.showSnackbar('登录成功', 'success')
            this.handleLoginSucceed(data)
            break
          case -403:
            // console.log('password wrong')
            this.showSnackbar('密码错误', 'error')
            break
          case -404:
            // console.log('user not found')
            this.showSnackbar('用户不存在', 'error')
            break
          default:
            this.showSnackbar('登录失败, 请稍后重试', 'error')
            break
        }
        this.loading = false
      })
    },
    handleLoginSucceed(data) {
      this.store.loginSucceed(data.idtoken, data.jwt, this.phoneNum, this.intercode, data.userId)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000)
    },
    sendVerificationCode() {
      console.log('sendVerificationCode: ', this.phoneNum, this.intercode);
      // const isValid = this.$refs.form.validate();
      if (!this.phoneNum) {
        this.showSnackbar('请输入有效的手机号码', 'error');
        return;
      }
      getCode(this.intercode, this.phoneNum).then(res => {
        console.log('sendVerificationCode: ', res);
        if (res.data.retCode === 0) {
          this.showSnackbar('验证码已发送', 'primary');
          this.startCountdown(); // 开始倒计时
        } else if (res.data.retCode === -403) {
          if (res.data.exception === '每天最多获取5条信息') {
            this.showSnackbar('发送验证码失败，每日发送次数已达上限', 'error');
          } else {
            this.showSnackbar('用户不存在, 请先注册', 'error');
          }
        } else {
          this.showSnackbar('验证码发送失败, 请稍后重试', 'error');
        }
      });
    },
    startCountdown() {
      this.codeCount = 60;
      const interval = setInterval(() => {
        if (this.codeCount > 0) {
          this.codeCount--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    submitFormByCode() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (!this.agree) {
        alert('请您同意用户协议，继续登录');
        return;
      }
      console.log('提交表单:', {
        phoneNum: this.phoneNum,
        verificationCode: this.verificationCode,
        intercode: this.intercode,
        os: 1
      });
      this.codeLogin()
    },
    codeLogin() {
      this.loading = true
      codeLogin(this.phoneNum, this.verificationCode, 1, this.intercode).then(res => {
        this.loading = false
        const data = res.data
        switch (data.retCode) {
          case 0:
            this.showSnackbar('登录成功', 'success')
            this.handleLoginSucceed(data)
            break
          case -403:
            this.showSnackbar('验证码错误, 请重新输入', 'error')
            break
          case -404:
            this.showSnackbar('用户不存在, 请先注册', 'error')
            break
          default:
            this.showSnackbar('登录失败, 请稍后重试', 'error')
            break
        }
      })
    },
    showSnackbar(text, color) {
      if (color) {
        this.color = color
      }
      this.text = text
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
.order-content {
  margin: 20% auto 0 auto;
  padding: 10px 20px;
}

.logo {
  margin: 0 auto;
  border-radius: 10%;
  display: block;
}

.form-info {
  margin-top: 40px;
}

.login {
  margin: 0 auto;
  width: 65%;
  font-size: 20px;
  display: block;
}
</style>