<template>
  <div class="bottom-navigation">
    <v-bottom-navigation v-model="currentValue" color="orange darken-4" grow>
      <v-btn :value="0" @click="navigateTo('BookingPage')">
        <span class="text-subtitle-2">{{ $vuetify.lang.t("$vuetify.book") }}</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn :value="1" @click="navigateTo('OrdersPage')">
        <span class="text-subtitle-2">{{ $vuetify.lang.t("$vuetify.orders") }}</span>
        <v-icon>view_list</v-icon>
      </v-btn>
      <v-btn :value="2" @click="navigateTo('ProfilePage')">
        <span class="text-subtitle-2">{{ $vuetify.lang.t("$vuetify.me") }}</span>
        <v-icon>person</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "BottomNavigation",
  data() {
    return {
      currentValue: 0,
    };
  },
  computed: {
    // 计算当前路由对应的按钮值
    currentRoute() {
      const routeName = this.$route.name;
      switch (routeName) {
        case "HomePage":
          return 0;
        case "OrdersPage":
          return 1;
        case "ProfilePage":
          return 2;
        default:
          return 0;
      }
    },
  },
  watch: {
    $route() {
      this.currentValue = this.currentRoute;
    },
  },
  methods: {
    navigateTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName});
      }
    },
  },
  created() {
    this.currentValue = this.currentRoute;
  },
};
</script>

<style scoped>
.bottom-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}
</style>
