import {defineStore} from "pinia";

export const useStore = defineStore('store', {
    state: () => ({
        idtoken: null,
        jwt: null,
        phone: null,
        intercode: '86',
        userId: null,
        isLogined: false
    }),
    getters: {},
    actions: {
        loginSucceed(idtoken, jwt, phone, intercode, userId) {
            this.idtoken = idtoken
            this.jwt = jwt
            this.phone = phone
            this.intercode = intercode
            this.userId = userId
            this.isLogined = true
            console.log('loginSucceed: \n', "idtoken:", idtoken, "jwt:", jwt, "phone:", phone, "intercode:", intercode, "userId:", userId)
        },
        logout() {
            this.idtoken = null;
            this.jwt = null;
            this.phone = null;
            this.userId = null;
            this.isLogined = false;
            console.log('logout')
        },
    },
    persist: {
        storage: localStorage,
    }
})