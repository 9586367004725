<template>
  <div class="bottom-bar">
    <v-footer padless>
      <v-col :style="{backgroundColor: backgroundColor,height: computedHeight}" class="bottom" cols="12">
        <div :class="leftClass" style="margin: auto 0;color: black">
          <slot name="left">左侧默认内容</slot>
        </div>
        <div :class="rightClass" style="display: flex; align-items: center; gap: 10px;color: black">
          <slot name="right">右侧默认内容</slot>
        </div>
      </v-col>
    </v-footer>
  </div>

</template>

<script>
export default {
  name: 'BottomBar',
  props: {
    backgroundColor: {
      type: String,
      // default: '#1565C0',
      default: '#ffffff',
    },
    leftClass: {
      type: String,
      default: 'text-h6',
    },
    rightClass: {
      type: String,
      default: 'text-subtitle-2',
    },
    height: {
      type: String,
      default: '60px',
    },
  },
  computed: {
    computedBackgroundColor() {
      return `${this.backgroundColor} lighten-1`;
    },
    computedHeight() {
      return this.height;
    },
  },
};
</script>

<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  color: #ffffff;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;

}

.bottom-bar {
  z-index: 1000;
}
</style>
