<template>
  <div class="content">
    <LoadingIndicator :value="loading"/>
    <BottomNavigation/>
    <TopBar title="我的订单" left-icon="view_list" :has-shadow="false" @left-icon-click="console.log('我的订单')"/>
    <v-tabs
        style="margin-top: 55px;"
        fixed-tabs
        background-color="#e34146"
        dark
        v-model="selectedTab"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab @click="getShowUserOrder(category)" v-for="(category, index) in headCategory" :key="index">
        <strong style="font-size: 16px">{{ category.desc }}</strong>
      </v-tab>
    </v-tabs>

    <div class="order-content text-subtitle-1">
      <v-card shaped flat
              @click="goToOrderDetail(order.id)" v-for="(order,index) in dataArray" :key="index">
        <div class="inner-card">
          <div class="order-info-line">
            <span style="margin: auto 0"><v-icon>storefront</v-icon>&nbsp;{{ order.merchantName }}</span>
            <strong style="color:red;">{{ order.orderStatusDesc }}</strong>
          </div>
          <div class="order-info-line">
            <span>订单编号: </span>
            <span class="text-red">{{ order.orderNumber }}</span>
          </div>
          <div class="" style="display: flex;gap: 15px;margin-top: 10px">
            <div>
              <img style="border-radius: 10px" width="100" :src="order.imageUrl" alt="">
            </div>
            <div style="flex: 1">
              <span>{{ order.title }}</span>
              <div style="display: flex;flex-direction: column;align-items: flex-end">
                <span style="color: red">{{ order.currency }}{{ order.total }}</span>
                <span>✖{{ order.amount }}</span>
                <span>在线支付: ¥ {{ order.realPayAmount }}</span>
              </div>
            </div>
          </div>
          <v-divider style="margin-bottom: 5px"/>
          <span>下单时间: {{ dayjs(order.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </div>
      </v-card>
    </div>
    <span style="margin: 0 auto" v-if="dataArray.length === 0">
        没有更多数据了
    </span>
  </div>
</template>

<script>
import BottomNavigation from "@/components/common/navigation/BottomNavigation.vue";
import TopBar from "@/components/common/navigation/TopBar.vue";
import {getShowUserOrder, getShowUserOrderStatus} from "@/api/worker";
import dayjs from "dayjs";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";

export default {
  name: "OrdersPages",
  computed: {
    dayjs() {
      return dayjs
    }
  },
  components: {LoadingIndicator, TopBar, BottomNavigation},
  mounted() {
    this.formatElements()
    this.getShowUserOrderStatus()
  },
  data() {
    return {
      selectedTab: 0,
      headCategory: [
        {value: '', desc: '全部'}
        // {name: '全部', value: 0}, {name: '待付款', value: 1}, {name: '已付款', value: 2},
        // {name: '已接单', value: 3}, {name: '服务中', value: 4}, {name: '完成', value: 5},
        // {name: '待评价', value: 6}, {name: '订单取消', value: 7}
      ],
      status: '',
      dataArray: [],
      pageNo: 1,
      totalPages: 0,
      pageSize: 10,
      loading: false,
    }
  },
  methods: {
    formatElements() {
      const prevButton = document.querySelector('.v-slide-group__prev');
      const nextButton = document.querySelector('.v-slide-group__next');
      if (prevButton) {
        prevButton.remove();
      }
      if (nextButton) {
        nextButton.remove();
      }
    },
    getShowUserOrderStatus() {
      getShowUserOrderStatus().then(res => {
        this.headCategory.push(...res.data.result)
        // this.headCategory = res.data.result
        console.log('getShowUserOrderStatus: ', this.headCategory)
        this.getShowUserOrder(this.headCategory[0])
      })
    },
    getShowUserOrder(category) {
      this.loading = true
      this.status = category.value
      getShowUserOrder(0, this.pageNo, this.pageSize, this.status).then(res => {
        const data = res.data.result
        this.dataArray = data.records
        this.totalPages = data.total
        console.log('getShowUserOrder.dataArray: ', this.dataArray)
        this.loading = false
      })
    },
    goToOrderDetail(orderId) {
      this.$router.push({name: 'OrderDetail', params: {orderId: orderId}});
    }
  }
}
</script>

<style scoped>
.content {
  background-color: #f6f6f6;
  min-height: 100vh;
}

.order-content {
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inner-card {
  padding: 10px 10px;
}

.order-info-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
