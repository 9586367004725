<template>
  <div>
    <v-app-bar
        :class="occupyTop ? 'bar fixed' : 'bar'"
        :color="color"
        :dark="dark"
        :flat="!hasShadow"
    >
      <v-icon
          style="padding-left: 0"
          v-if="leftIcon"
          @click="handleLeftIconClick"
      >
        {{ leftIcon }}
      </v-icon>
      <v-toolbar-title><strong>{{ title }}</strong></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon
          v-if="rightIcon"
          @click="handleRightIconClick"
      >
        {{ rightIcon }}
      </v-icon>
    </v-app-bar>
    <div :style="{ marginTop: BottomMargin + 'px' }"></div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  props: {
    title: {
      type: String,
      default: "",
    },
    leftIcon: {
      type: String,
      default: "",
    },
    rightIcon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      // default: "primary",
      default: "#e34146",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    occupyTop: {
      type: Boolean,
      default: true,
    },
    hasShadow: {
      type: Boolean,
      default: true,
    },
    BottomMargin: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    handleLeftIconClick() {
      if (this.$listeners['left-icon-click']) {
        this.$emit('left-icon-click');
      } else {
        window.history.back();
      }
    },
    handleRightIconClick() {
      this.$emit('right-icon-click');
    }
  }
};
</script>

<style scoped>
.bar {
  width: 100%;
  z-index: 1000;
}

.fixed {
  position: fixed;
  top: 0;
  //color: #e34146;
}
</style>
