import { render, staticRenderFns } from "./SelectServiceTime.vue?vue&type=template&id=1d2c60cf&scoped=true"
import script from "./SelectServiceTime.vue?vue&type=script&lang=js"
export * from "./SelectServiceTime.vue?vue&type=script&lang=js"
import style0 from "./SelectServiceTime.vue?vue&type=style&index=0&id=1d2c60cf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d2c60cf",
  null
  
)

export default component.exports