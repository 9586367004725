<template>
  <div class="top-back">
    <v-btn icon @click="goBack" class="top-back-btn" large>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "TopBack",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.top-back {
  position: relative;
}

.top-back-btn {
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  z-index: 10;
}
</style>
