<template>
  <div>
    <BottomNavigation/>
    <CustomerService ref="customerService"/>
    <LanguageSetting ref="languageSetting"/>
    <div class="top">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
               v-on="on"
               class="setting">
            <v-icon color="white">settings</v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item @click="openLanguageDialog">
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.modifyLanguage") }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item @click="logout" v-if="store.idtoken">
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.signOut") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="top-info">
        <v-avatar color="primary" size="70">
          <img v-if="store.idtoken"
               src="https://static.vecteezy.com/system/resources/previews/008/302/462/original/eps10-grey-user-icon-or-logo-in-simple-flat-trendy-modern-style-isolated-on-white-background-free-vector.jpg"
               alt="">
          <img v-else src="https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png" alt="">
        </v-avatar>
        <div class="text-h5" style="color: white;margin: auto 0">
          <div v-if="!store.idtoken">
            <strong @click="ToLoginPage">{{ $vuetify.lang.t("$vuetify.Login") }}</strong>｜
            <strong @click="ToRegisterPage">{{ $vuetify.lang.t("$vuetify.register") }}</strong>
          </div>
          <strong v-else>{{ store.phone }}</strong>
        </div>
      </div>
    </div>
    <v-card class="card">
      <div class="item" @click="showCustomerService">
        <img width="45" :src="require('@/assets/pinkCustomerService@2x.png')" alt="">
        <div>{{ $vuetify.lang.t("$vuetify.Contactservice") }}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import BottomNavigation from "@/components/common/navigation/BottomNavigation.vue";
import {useStore} from "@/config/store";
import CustomerService from "@/components/common/CustomerService.vue";
import LanguageSetting from "@/components/profile/LanguageSetting.vue";

export default {
  name: "ProfilePage",
  components: {LanguageSetting, CustomerService, BottomNavigation},
  computed: {
    store() {
      return useStore()
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    ToLoginPage() {
      this.$router.push({name: 'LoginPage'})
    },
    ToRegisterPage() {
      this.$router.push({name: 'RegisterPage'})
    },
    logout() {
      localStorage.removeItem('store');
      this.store.logout();
      window.location.reload()
    },
    showCustomerService() {
      this.$refs.customerService.dialog = true;
    },
    openLanguageDialog() {
      this.$refs.languageSetting.dialog = true;
    }
  }
}
</script>

<style scoped>
.top {
  width: 100%;
  background: linear-gradient(to right, #e14f47, #e66a5a);
}

.setting {
  margin: 30px 10px 0 0;
  float: right;
}

.top-info {
  margin: 50px 20px 30px 20px;
  display: flex;
  gap: 30px;
  flex-direction: row;
}

.card {
  margin: 160px 10px 0 10px;
  padding: 5px 10px;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  gap: 5px;
}

</style>